var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full dF aC jC",staticStyle:{"min-height":"100vh","background-color":"#f2f4f8"}},[_c('BHLoading',{attrs:{"show":_vm.loading}}),_c('div',{staticClass:"w-full",staticStyle:{"max-width":"36rem","margin-left":"auto","margin-right":"auto"}},[_vm._m(0),_c('div',{staticClass:"pl-5 pr-5 pt-5 pb-5 bg-white text-center w-full",staticStyle:{"box-shadow":"0 4px 38px 0 rgb(22 21 55 / 6%), 0 0 21px 0 rgb(22 21 55 / 3%)"}},[_c('div',{staticClass:"text-dark font-size-30"},[_vm._v("Log In with")]),_vm._m(1),_c('a-form',{staticClass:"mt-5 mb-3"},[_c('a-form-item',{style:([ _vm.emailRequirements ? { '': '' } : { 'margin-bottom': '0 !important' }, ])},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            {
                                rules: [
                                { required: true, message: 'Please input your username!' },
                                ],
                            },
                            ]),expression:"[\n                            'email',\n                            {\n                                rules: [\n                                { required: true, message: 'Please input your username!' },\n                                ],\n                            },\n                            ]"}],class:{ redBorder: !_vm.emailRequirements },style:([
                            _vm.emailRequirements
                                ? { '': '' }
                                : { 'border-color': 'var(--red) !important' },
                            ]),attrs:{"size":"large","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_c('a-tooltip',{attrs:{"slot":"suffix","overlayClassName":"change-tooltip-color"},slot:"suffix"},[_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.emailRequirements),expression:"!emailRequirements"}],staticStyle:{"color":"var(--red)"},attrs:{"type":"warning"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.emailRequirements),expression:"!emailRequirements"}],staticStyle:{"overflow":"auto"}},[_c('p',{staticStyle:{"margin-bottom":"0px !important","text-align":"left","color":"var(--red)"}},[_vm._v(" Hmm... That's not a valid email address. ")])])],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'password',
                        {
                            rules: [
                            { required: true, message: 'Please input your password!' },
                            ],
                        },
                        ]),expression:"[\n                        'password',\n                        {\n                            rules: [\n                            { required: true, message: 'Please input your password!' },\n                            ],\n                        },\n                        ]"}],staticClass:"br0",attrs:{"size":"large","placeholder":"Password","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('a-button',{staticClass:"text-center btn btn-primary w-100 font-weight-semibold font-size-18 br0",attrs:{"type":"primary","size":"large"},on:{"click":_vm.handleSubmit}},[_vm._v("Log in")])],1),_c('div',{staticClass:"provider-link",on:{"click":function($event){return _vm.$router.push('/settings')}}},[_vm._v(" Select a different provider ")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5 pb-5 dF jC align-items-end mt-auto"},[_c('img',{staticStyle:{"height":"40px"},attrs:{"src":require("@/assets/logo.svg"),"alt":"Bildhive Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-dark font-size-30 dF aC jC"},[_c('img',{staticClass:"mr-2",staticStyle:{"height":"40px"},attrs:{"src":require("@/assets/outlook.png"),"alt":"Outlook Logo"}}),_vm._v(" Microsoft Outlook ")])
}]

export { render, staticRenderFns }