<template>
    <div class="w-full dF aC jC" style="min-height: 100vh; background-color: #f2f4f8;">
        <BHLoading :show="loading" />
        <div class="w-full" style="max-width: 36rem; margin-left: auto; margin-right: auto;">
            <div class="pt-5 pb-5 dF jC align-items-end mt-auto">
                <img src="@/assets/logo.svg" alt="Bildhive Logo" style="height:40px;" />
            </div>
            <div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center w-full" style="box-shadow: 0 4px 38px 0 rgb(22 21 55 / 6%), 0 0 21px 0 rgb(22 21 55 / 3%);">
                <div class="text-dark font-size-30">Log In with</div>
                <div class="text-dark font-size-30 dF aC jC">
                    <img class="mr-2" src="@/assets/outlook.png" alt="Outlook Logo" style="height:40px;" />
                    Microsoft Outlook
                </div>
                <a-form class="mt-5 mb-3">
                    <a-form-item :style="[ emailRequirements ? { '': '' } : { 'margin-bottom': '0 !important' }, ]" >
                        <a-input
                            size="large"
                            placeholder="Email"
                            :class="{ redBorder: !emailRequirements }"
                            :style="[
                            emailRequirements
                                ? { '': '' }
                                : { 'border-color': 'var(--red) !important' },
                            ]"
                            v-decorator="[
                            'email',
                            {
                                rules: [
                                { required: true, message: 'Please input your username!' },
                                ],
                            },
                            ]"
                            v-model="email"
                        >
                            <a-tooltip slot="suffix" overlayClassName="change-tooltip-color">
                                <a-icon
                                    v-show="!emailRequirements"
                                    type="warning"
                                    style="color: var(--red)"
                                />
                            </a-tooltip>
                        </a-input>
                        <div v-show="!emailRequirements" style="overflow: auto;">
                            <p
                            style="margin-bottom: 0px !important; text-align: left; color: var(--red);"
                            >
                            Hmm... That's not a valid email address.
                            </p>
                        </div>
                    </a-form-item>
                    <a-form-item>
                    <a-input-password
                        size="large"
                        placeholder="Password"
                        class="br0"
                        v-model="password"
						autocomplete="off"
                        v-decorator="[
                        'password',
                        {
                            rules: [
                            { required: true, message: 'Please input your password!' },
                            ],
                        },
                        ]"
                    />
                    </a-form-item>

                    <a-button
                    type="primary"
                    @click="handleSubmit"
                    size="large"
                    class="text-center btn btn-primary w-100 font-weight-semibold font-size-18 br0"
                    >Log in</a-button
                    >
                </a-form>
                <div class="provider-link" @click="$router.push('/settings')">
                    Select a different provider
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BHLoading from 'bh-mod/components/common/Loading'

    let tld = window.location.href.includes('bildhive.com')? 'com' : 'dev'

    export default {
        components:{
            BHLoading
        },
        data() {
            return {
                loading:false,
                email:'',
                password:''
            }
        },
        watch:{

        },
        computed:{
            emailRequirements() {
                let requirements = true;
                if (
                    (this.email.match(/[@]+/) == null ||
                    this.email.match(/[.]+/) == null) &&
                    this.email.length > 4
                ) {
                    requirements = false;
                }
                return requirements;
            },

        },
        methods:{
            handleSubmit(){
                console.log('Handle submit...')
                if (!this.emailRequirements || this.email.length < 1) return this.$message.error('Please enter a valid email address.');
                if (this.password.length < 1) return this.$message.error('Please enter a password.');

                let sendObj = {
                    email: this.email,
                    password: this.password
                }
                this.loading = true;
                this.$api.post('/contacts/:instance/authorize/outlook', sendObj).then(({data}) => {
                    this.loading = false;
                    if (data.success) {
                        this.$message.success('Successfully logged in.');
                        location.href = `https://contacts.bildhive.${tld}/settings`;
                    }
                }).catch(err => {
                    this.loading = false;
                })

            }
        },
        mounted(){

        },
        created() {


        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .br0 {
        border-radius: 0 !important;
    }
    .redBorder {
        border: 1px solid var(--red) !important;
    }
    .provider-link {
        margin-top: 0.75em;
        display: inline-block;
        cursor: pointer;
        color:#595959;
        transition: color 0.2s ease-in-out;
    }
    .provider-link:hover {
        color: var(--orange);
        transition: color 0.2s ease-in-out;
    }
</style>
